.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.charts-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.chart-item {
  flex: 1 1 calc(50% - 40px); /* Adjust the percentage to control the width of the columns */
  box-sizing: border-box;
  margin: 10px;
  border: 2px solid #ccc; /* Add this line for the border */
  padding: 10px; /* Add some padding if needed */
  border-radius: 8px; /* Optional: Add rounded corners */
}

.header {
  text-align: center;
  font-size: 1.5em; /* Ensure the header text is the same size */
}

.header p {
  margin: 5px 0; /* Add some margin to separate paragraphs */
}

.button {
	color: #04AA6D;
	padding: 15px 32px;
	text-align: center;
}